<template >
    <main v-show="isReady" class="kb-login">
      <div class="login-cover">
        <img v-if="isMobile" src="@/assets/lxp/mobile/images/login/bg_2.jpg" alt="">
        <video v-else src="@/assets/lxp/videos/login.mp4" autoplay="" muted="muted" loop=""></video>
      </div>
      <div class="login-component">
        <div class="logo">
          <img src="../../assets/lxp/images/login/kb_logo.svg" alt="">
        </div>
        <div class="auth-body">
          <div class="auth-forms">
            <div class="field-area">
              <div class="kb-form-input-group">
                <input type="text" class="kb-form-input" placeholder="접속 대상 아이디 입력"
                       v-model.trim="info.lrnerId"
                       @keyup.enter="enterAdminId"
                >
                <div class="input-box" style="border-radius: 32px;"></div>
              </div>
            </div>

            <div class="field-area">
              <div class="kb-form-input-group">
                <input type="text" class="kb-form-input" placeholder="운영자 직원번호 입력"
                       v-model.trim="info.adminId"
                       @keyup.enter="login"
                       style="color: #FFFFFF"
                       id="enterAdminIdEle"
                >
                <div class="input-box" style="border-radius: 32px;border-color: rgba(255,255,255,.1); background-color: rgba(0,0,0,0.2)"></div>
              </div>
            </div>

            <div class="kb-form-group">
              <div class="kb-form-check kb-form-check-inline" style="flex-wrap: initial !important; height:100px; margin:1em;border-radius: 5px;border-color: rgba(255,255,255,.1);">
                <input v-model="promiFlag" type="checkbox" class="kb-form-check-input" name="chkMember2" id="result3">
                <label for="result3" class="auth-list-target" style="margin-left: 0.3em;">
                  <span class="text bold text-primary" >타직원 로그인은 업무 목적으로만 사용할 것을 서약합니다.</span>
                </label>
              </div>
            </div>

          </div>
          <button v-if="!certFlag" class="btn-lg btn btn-success kb-btn-lg kb-btn-login"
                  :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                  @click="loginReqMobCert"
                  style="border-radius: 32px; background-color: #61b045 !important;">
            <span class="text">KB국민인증서 인증요청</span>
          </button>

          <button v-if="certFlag" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                  :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                  @click="login"
                  style="border-radius: 32px">
            <span class="text">인증완료 로그인</span>
          </button>

        </div>
      </div>
    </main>
    <!-- end::kb-login -->
    <!-- begin::kb-footer -->
<!--    <footer class="kb-footer" id="kb-footer">-->
<!--      <div class="footer-row footer-component">-->
<!--        <div class="footer-copyright">-->
<!--          <p class="copyright">Copyright (C) KB E-HRD. All Rights Reserved</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </footer>-->
</template>
<script>
import {computed, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_MLOGIN_SIGNIN_ADMIN_AUTH, TOKEN_KEY} from '@/store/modules/auth/auth';
import {isSuccess,getResult} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {saveToken} from '@/assets/js/localstorage.service';
import {useRoute, useRouter} from 'vue-router';
import ApiService from '@/assets/js/api.service';
import {setInside} from '@/assets/js/modules/auth/auth-common.js'
import {ACT_SEND_MLOGIN_PUSH_SEND} from "@/store/modules/certification/certification";

export default {
  name: 'Login',
  components: {},
  setup() {
    const store = useStore();
    const isReady = ref(false);
    const isMobile = computed(() => store.state.auth.isMobile);
    const isLoading = ref(false);
    const certFlag = ref(false);
    const promiFlag = ref(false);
    const {showMessage} = useAlert();

    const info = reactive({
      lrnerId: '',
      adminId: '',
    });
    const certPayload = reactive({
      lrnerId: '',
      serialNoSn: 0,
    });



    const validate = () => {
      let result = false;
      isLoading.value = false;

      if(!info.lrnerId) {
        showMessage('접속대상 직원번호를 입력해주세요.');
        return false;
      }

      if(!info.adminId){
        showMessage('접속하시는 운영자 직원번호를 입력해주세요.');
        return false;
      }

      if(info.adminId==info.lrnerId){
        showMessage('타직원 로그인 기능은 본인의 인증목적으로 이용하실 수 없습니다.');
        return false;
      }

      if(!promiFlag.value){
        showMessage('타직원 로그인 유의사항을 확인하시고 서약하세요');
        return false;
      }
      result = true;

      return result;
    }

    const route = useRoute();
    const router = useRouter();

    const enterAdminId = () =>{
      document.getElementById("enterAdminIdEle").focus();
    }

    const adminChk =()=>{ //Admin
      ApiService.post('/v1/app/ips/adm', {mngChk:'Y'}).then(res => {
        if(getResult(res).number === 500) {
          // isReady.value = true;
          router.push({path:'/signin'});
          //location.href='/app/signin';
        }else{
          isReady.value = true;
        }
      })
    }


    if( !store.state.auth.isInside) { //내부망체크 그대로
      ApiService.post('/v1/app/ips/mngs', {}).then(res => {
        if(isSuccess(res)) {
          setInside(1)
        }
      })
    }
    adminChk();


    const authCount = ref(0);

    // eslint-disable-next-line no-unused-vars
    const callCert = () =>{
      console.log("cert call");
      certFlag.value = true;

      store.dispatch(`certification/${ACT_SEND_MLOGIN_PUSH_SEND}`,{
        lrnerId: info.adminId,
        notiPfmCds: ["2037009"]
      }).then(res => {
        if (res.result == "L098") {
          showMessage("사용자 정보가 존재하지 않습니다.<br> 관리자에게 문의 바랍니다.");
          authCount.value = 0;
        } else if (res.result == "L099") {
          showMessage("알림 수신 동의가 되어 있지 않아<br> 서비스 이용이 불가능합니다.<br>스타뱅킹 앱을 실행하여 알림 수신 동의 후에<br>이용 바랍니다.");
          authCount.value = 0;
        } else if (res.result == "L001") {
          showMessage("KB 국민인증서가 발급되지 않았습니다.<br> 발급 후 시도 바랍니다.");
          authCount.value = 0;
        } else if (res.result == "L301") {
          showMessage("최근 인증을 시도한 이력이 있습니다.<br> 잠시 후 시도 바랍니다.");
        } else if(res.result == "0000") {
          certPayload.serialNoSn = res.serialNoSn;
        } else {
          showMessage("시스템 에러가 발생했습니다. 잠시 후 다시 시도 바랍니다.");
          authCount.value = 0;
        }
      }).catch(e => {
        showMessage(`시스템 에러가 발생했습니다. 잠시 후 다시 시도 바랍니다. <br/>${e.message}`,
        ()=>{
          certFlag.value = true;
        });
        console.error(e);
      });
    }

    const loginReqMobCert =() =>{
      if(validate()){
        showMessage(`타직원 로그인을 요청하신 운영자(${info.adminId})에게 <br/>KB국민인증서 인증 요청을 발송합니다.<br/>다만, 비상경영체계하에 신속한 업무처리를 위해 국민인증서 인증절차를 일시적으로 생략합니다.<br/>타직원로그인 기능의 중요성을 다시한번 유의하시고 로그인 거래를 진행 해 주시기 바랍니다.`
            ,
            ()=>{
              certPayload.serialNoSn = 0;
              certFlag.value = true;
            }
            );
      }
    }
    const login = () => {
      if (isLoading.value) return;
      isLoading.value = true;

       if(validate()) {
         let admAuth = {
           mngrId : info.adminId,
           mngrNm : info.lrnerId,
           mngrSn : certPayload.serialNoSn
         }
        store.dispatch(`auth/${ACT_MLOGIN_SIGNIN_ADMIN_AUTH}`, admAuth).then(res => {
          if(isSuccess(res)) {
            saveToken(TOKEN_KEY, res.authToken);
            if (route.query.redirect && !route.query.redirect.includes('/signin') && !route.query.redirect.includes('/login')) {
              router.push({path: route.query.redirect});
            }else {
              router.push('/main');
            }
          } else {
            showMessage('접속 대상직원 번호 또는 국민인증서 인증여부를 <br/>다시 확인하시고 처음부터 다시 시작 해 주세요', ()=>{
              location.reload();
            });
          }
          isLoading.value = false;
        }).catch(() => {
          alert('error');
          isLoading.value = false;
        });
      }

    };


    return {
      isMobile,
      isLoading,
      info,
      isReady,
      login,
      enterAdminId,
      loginReqMobCert,
      certFlag,
      promiFlag
    };
  },
};
</script>
